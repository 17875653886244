body,
html {
  position: fixed;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #1b2735;
  overflow-x: hidden;
}

.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  background: -webkit-linear-gradient(top, rgb(153 0 0) 21%, rgb(255 0 0) 72%)
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 10px;
  height: calc(100% - 20px);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pin-field {
  background-color: rgb(248, 249, 250);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.3rem;
  font-size: 2rem;
  margin: 0.25rem;
  height: 3.5rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: background, color, border, box-shadow, transform;
  width: 3rem;
}

.pin-field:focus {
  border-color: rgb(0, 123, 255);
  outline: none;
  transform: scale(1.05);
}

.pin-field:invalid {
  animation: shake 3 linear 75ms;
  border-color: rgb(220, 53, 69);
  box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

swd-pin-field[error] .pin-field {
  animation: shake 3 linear 75ms;
  border-color: rgb(220, 53, 69);
  background-color: rgba(220, 53, 69, 0.5);
}

swd-pin-field[completed] .pin-field {
  border-color: rgb(40, 167, 69);
  background-color: rgba(40, 167, 69, 0.25);
}

@keyframes shake {
  from {
    transform: scale(1.05) translateY(-5%);
  }
  to {
    transform: scale(1.05) translateY(5%);
  }
}

.main-button {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  border-radius: 25px;
  padding: 10px 24px;
  background-color: #4CAF50;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  border: none;
}
