.Card {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    flex-direction: column;
    width: calc(100% - 2px);
}
.Card.valid {
    border: 1px solid rgb(40, 167, 69);
    box-shadow: 0 0 10px rgb(40 167 69);
}

.Card.invalid {
    border: 1px solid rgb(220, 53, 69);
    box-shadow: 0 0 10px rgb(220, 53, 69);
}

.Card .imageWrapper {
    margin: 6px 6px 0px;
}

.Card .image {
    width: 100%;
    height: 100%;
    border-radius: 25px;
}

.Card .body {
    width: 100%;
}

.Card .textWrapper {
    margin: 6px 20px 6px 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
    color: black;
}

.Card .title {
    margin: 0px 6px 0px 0px;
}

.Card .description {
    margin: 0px;
}
