.btn {
    margin: 20px auto;
    border-radius: 25px;
    background-color: #52c41a;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    display: inline-block;
    padding: 15px 50px;
    color: #ffffff;
    min-height: 20px;
    max-height: 20px;
}
.btn.btn-blue {
    background-color: #0091ce;
}

.btn.btn-disabled {
    background-color: #23560b;
    color: #888888;
}
.btn.btn-blue.btn-disabled {
    background-color: #004765;
    color: #888888;
}

.center {
    text-align: center
}

/* ripple */
.ripple {
    position: relative;
    overflow: hidden;
}
.ripple .rippleContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.ripple .rippleContainer span {
    transform: scale(0);
    border-radius: 100%;
    position: absolute;
    opacity: 0.75;
    background-color: rgba(0, 0, 0, 0.5);
    animation: ripple 850ms;
}

@keyframes ripple {
    to {
        opacity: 0;
        transform: scale(2);
    }
}
