.snow {
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
}

.snow:nth-child(1) {
  opacity: 0.9803;
  transform: translate(21.1511vw, -10px) scale(0.8036);
  animation: fall-1 17s -28s linear infinite;
}

@keyframes fall-1 {
  61.661% {
    transform: translate(14.3889vw, 61.661vh) scale(0.8036);
  }

  to {
    transform: translate(17.77vw, 100vh) scale(0.8036);
  }
}

.snow:nth-child(2) {
  opacity: 0.3336;
  transform: translate(8.7938vw, -10px) scale(0.9457);
  animation: fall-2 15s -26s linear infinite;
}

@keyframes fall-2 {
  46.853% {
    transform: translate(0.0387vw, 46.853vh) scale(0.9457);
  }

  to {
    transform: translate(4.41625vw, 100vh) scale(0.9457);
  }
}

.snow:nth-child(3) {
  opacity: 0.5548;
  transform: translate(54.555vw, -10px) scale(0.1643);
  animation: fall-3 10s -15s linear infinite;
}

@keyframes fall-3 {
  30.656% {
    transform: translate(60.5799vw, 30.656vh) scale(0.1643);
  }

  to {
    transform: translate(57.56745vw, 100vh) scale(0.1643);
  }
}

.snow:nth-child(4) {
  opacity: 0.8171;
  transform: translate(34.0202vw, -10px) scale(0.988);
  animation: fall-4 19s -6s linear infinite;
}

@keyframes fall-4 {
  36.391% {
    transform: translate(38.7969vw, 36.391vh) scale(0.988);
  }

  to {
    transform: translate(36.40855vw, 100vh) scale(0.988);
  }
}

.snow:nth-child(5) {
  opacity: 0.5303;
  transform: translate(28.6641vw, -10px) scale(0.6098);
  animation: fall-5 21s -4s linear infinite;
}

@keyframes fall-5 {
  73.173% {
    transform: translate(34.3871vw, 73.173vh) scale(0.6098);
  }

  to {
    transform: translate(31.5256vw, 100vh) scale(0.6098);
  }
}

.snow:nth-child(6) {
  opacity: 0.6492;
  transform: translate(30.5178vw, -10px) scale(0.2855);
  animation: fall-6 11s -29s linear infinite;
}

@keyframes fall-6 {
  66.676% {
    transform: translate(22.7968vw, 66.676vh) scale(0.2855);
  }

  to {
    transform: translate(26.6573vw, 100vh) scale(0.2855);
  }
}

.snow:nth-child(7) {
  opacity: 0.1334;
  transform: translate(16.691vw, -10px) scale(0.0968);
  animation: fall-7 14s -16s linear infinite;
}

@keyframes fall-7 {
  37.076% {
    transform: translate(12.3431vw, 37.076vh) scale(0.0968);
  }

  to {
    transform: translate(14.51705vw, 100vh) scale(0.0968);
  }
}

.snow:nth-child(8) {
  opacity: 0.3946;
  transform: translate(80.5174vw, -10px) scale(0.9758);
  animation: fall-8 14s -23s linear infinite;
}

@keyframes fall-8 {
  53.622% {
    transform: translate(77.8198vw, 53.622vh) scale(0.9758);
  }

  to {
    transform: translate(79.1686vw, 100vh) scale(0.9758);
  }
}

.snow:nth-child(9) {
  opacity: 0.4946;
  transform: translate(30.7419vw, -10px) scale(0.3955);
  animation: fall-9 30s -6s linear infinite;
}

@keyframes fall-9 {
  57.936% {
    transform: translate(26.5911vw, 57.936vh) scale(0.3955);
  }

  to {
    transform: translate(28.6665vw, 100vh) scale(0.3955);
  }
}

.snow:nth-child(10) {
  opacity: 0.7448;
  transform: translate(2.3139vw, -10px) scale(0.7809);
  animation: fall-10 11s -17s linear infinite;
}

@keyframes fall-10 {
  77.508% {
    transform: translate(-4.0675vw, 77.508vh) scale(0.7809);
  }

  to {
    transform: translate(-0.8768vw, 100vh) scale(0.7809);
  }
}

.snow:nth-child(11) {
  opacity: 0.5842;
  transform: translate(16.8811vw, -10px) scale(0.4401);
  animation: fall-11 17s -23s linear infinite;
}

@keyframes fall-11 {
  58.029% {
    transform: translate(17.0518vw, 58.029vh) scale(0.4401);
  }

  to {
    transform: translate(16.96645vw, 100vh) scale(0.4401);
  }
}

.snow:nth-child(12) {
  opacity: 0.3869;
  transform: translate(81.8253vw, -10px) scale(0.3438);
  animation: fall-12 15s -16s linear infinite;
}

@keyframes fall-12 {
  66.477% {
    transform: translate(78.8249vw, 66.477vh) scale(0.3438);
  }

  to {
    transform: translate(80.3251vw, 100vh) scale(0.3438);
  }
}

.snow:nth-child(13) {
  opacity: 0.5396;
  transform: translate(54.7159vw, -10px) scale(0.0207);
  animation: fall-13 12s -11s linear infinite;
}

@keyframes fall-13 {
  35.227% {
    transform: translate(63.9921vw, 35.227vh) scale(0.0207);
  }

  to {
    transform: translate(59.354vw, 100vh) scale(0.0207);
  }
}

.snow:nth-child(14) {
  opacity: 0.4834;
  transform: translate(35.8441vw, -10px) scale(0.8313);
  animation: fall-14 19s -15s linear infinite;
}

@keyframes fall-14 {
  47.215% {
    transform: translate(39.8666vw, 47.215vh) scale(0.8313);
  }

  to {
    transform: translate(37.85535vw, 100vh) scale(0.8313);
  }
}

.snow:nth-child(15) {
  opacity: 0.3876;
  transform: translate(67.1184vw, -10px) scale(0.2708);
  animation: fall-15 24s -5s linear infinite;
}

@keyframes fall-15 {
  53.298% {
    transform: translate(61.5059vw, 53.298vh) scale(0.2708);
  }

  to {
    transform: translate(64.31215vw, 100vh) scale(0.2708);
  }
}

.snow:nth-child(16) {
  opacity: 0.0072;
  transform: translate(67.0229vw, -10px) scale(0.3031);
  animation: fall-16 11s -26s linear infinite;
}

@keyframes fall-16 {
  78.583% {
    transform: translate(70.9404vw, 78.583vh) scale(0.3031);
  }

  to {
    transform: translate(68.98165vw, 100vh) scale(0.3031);
  }
}

.snow:nth-child(17) {
  opacity: 0.504;
  transform: translate(56.4054vw, -10px) scale(0.1811);
  animation: fall-17 17s -19s linear infinite;
}

@keyframes fall-17 {
  60.105% {
    transform: translate(59.1568vw, 60.105vh) scale(0.1811);
  }

  to {
    transform: translate(57.7811vw, 100vh) scale(0.1811);
  }
}

.snow:nth-child(18) {
  opacity: 0.4667;
  transform: translate(86.2223vw, -10px) scale(0.5294);
  animation: fall-18 10s -27s linear infinite;
}

@keyframes fall-18 {
  37.318% {
    transform: translate(86.4535vw, 37.318vh) scale(0.5294);
  }

  to {
    transform: translate(86.3379vw, 100vh) scale(0.5294);
  }
}

.snow:nth-child(19) {
  opacity: 0.8361;
  transform: translate(99.8658vw, -10px) scale(0.3658);
  animation: fall-19 19s -17s linear infinite;
}

@keyframes fall-19 {
  30.861% {
    transform: translate(103.5946vw, 30.861vh) scale(0.3658);
  }

  to {
    transform: translate(101.7302vw, 100vh) scale(0.3658);
  }
}

.snow:nth-child(20) {
  opacity: 0.9913;
  transform: translate(63.0243vw, -10px) scale(0.8828);
  animation: fall-20 17s -14s linear infinite;
}

@keyframes fall-20 {
  68.538% {
    transform: translate(72.2976vw, 68.538vh) scale(0.8828);
  }

  to {
    transform: translate(67.66095vw, 100vh) scale(0.8828);
  }
}

.snow:nth-child(21) {
  opacity: 0.4474;
  transform: translate(88.633vw, -10px) scale(0.9271);
  animation: fall-21 13s -2s linear infinite;
}

@keyframes fall-21 {
  76.796% {
    transform: translate(80.5961vw, 76.796vh) scale(0.9271);
  }

  to {
    transform: translate(84.61455vw, 100vh) scale(0.9271);
  }
}

.snow:nth-child(22) {
  opacity: 0.2019;
  transform: translate(40.6562vw, -10px) scale(0.8073);
  animation: fall-22 12s -10s linear infinite;
}

@keyframes fall-22 {
  38.024% {
    transform: translate(39.05vw, 38.024vh) scale(0.8073);
  }

  to {
    transform: translate(39.8531vw, 100vh) scale(0.8073);
  }
}

.snow:nth-child(23) {
  opacity: 0.9734;
  transform: translate(91.87vw, -10px) scale(0.5987);
  animation: fall-23 15s -1s linear infinite;
}

@keyframes fall-23 {
  45.29% {
    transform: translate(91.1195vw, 45.29vh) scale(0.5987);
  }

  to {
    transform: translate(91.49475vw, 100vh) scale(0.5987);
  }
}

.snow:nth-child(24) {
  opacity: 0.4382;
  transform: translate(54.9464vw, -10px) scale(0.2116);
  animation: fall-24 15s -29s linear infinite;
}

@keyframes fall-24 {
  33.815% {
    transform: translate(62.183vw, 33.815vh) scale(0.2116);
  }

  to {
    transform: translate(58.5647vw, 100vh) scale(0.2116);
  }
}

.snow:nth-child(25) {
  opacity: 0.1632;
  transform: translate(64.155vw, -10px) scale(0.3789);
  animation: fall-25 24s -9s linear infinite;
}

@keyframes fall-25 {
  39.81% {
    transform: translate(70.0154vw, 39.81vh) scale(0.3789);
  }

  to {
    transform: translate(67.0852vw, 100vh) scale(0.3789);
  }
}

.snow:nth-child(26) {
  opacity: 0.7455;
  transform: translate(37.5215vw, -10px) scale(0.8846);
  animation: fall-26 23s -8s linear infinite;
}

@keyframes fall-26 {
  30.566% {
    transform: translate(37.7213vw, 30.566vh) scale(0.8846);
  }

  to {
    transform: translate(37.6214vw, 100vh) scale(0.8846);
  }
}

.snow:nth-child(27) {
  opacity: 0.0167;
  transform: translate(6.8414vw, -10px) scale(0.2429);
  animation: fall-27 26s -13s linear infinite;
}

@keyframes fall-27 {
  37.654% {
    transform: translate(6.0768vw, 37.654vh) scale(0.2429);
  }

  to {
    transform: translate(6.4591vw, 100vh) scale(0.2429);
  }
}

.snow:nth-child(28) {
  opacity: 0.7177;
  transform: translate(89.5761vw, -10px) scale(0.9018);
  animation: fall-28 22s -28s linear infinite;
}

@keyframes fall-28 {
  64.052% {
    transform: translate(82.0842vw, 64.052vh) scale(0.9018);
  }

  to {
    transform: translate(85.83015vw, 100vh) scale(0.9018);
  }
}

.snow:nth-child(29) {
  opacity: 0.2488;
  transform: translate(41.15vw, -10px) scale(0.1602);
  animation: fall-29 14s -6s linear infinite;
}

@keyframes fall-29 {
  53.137% {
    transform: translate(37.901vw, 53.137vh) scale(0.1602);
  }

  to {
    transform: translate(39.5255vw, 100vh) scale(0.1602);
  }
}

.snow:nth-child(30) {
  opacity: 0.1446;
  transform: translate(41.4635vw, -10px) scale(0.5601);
  animation: fall-30 18s -12s linear infinite;
}

@keyframes fall-30 {
  34.329% {
    transform: translate(44.0529vw, 34.329vh) scale(0.5601);
  }

  to {
    transform: translate(42.7582vw, 100vh) scale(0.5601);
  }
}

.snow:nth-child(31) {
  opacity: 0.175;
  transform: translate(18.733vw, -10px) scale(0.773);
  animation: fall-31 12s -26s linear infinite;
}

@keyframes fall-31 {
  38.432% {
    transform: translate(13.1622vw, 38.432vh) scale(0.773);
  }

  to {
    transform: translate(15.9476vw, 100vh) scale(0.773);
  }
}

.snow:nth-child(32) {
  opacity: 0.5171;
  transform: translate(95.7167vw, -10px) scale(0.2934);
  animation: fall-32 15s -5s linear infinite;
}

@keyframes fall-32 {
  78.7% {
    transform: translate(92.6909vw, 78.7vh) scale(0.2934);
  }

  to {
    transform: translate(94.2038vw, 100vh) scale(0.2934);
  }
}

.snow:nth-child(33) {
  opacity: 0.9743;
  transform: translate(37.4115vw, -10px) scale(0.8753);
  animation: fall-33 25s -30s linear infinite;
}

@keyframes fall-33 {
  70.443% {
    transform: translate(36.7932vw, 70.443vh) scale(0.8753);
  }

  to {
    transform: translate(37.10235vw, 100vh) scale(0.8753);
  }
}

.snow:nth-child(34) {
  opacity: 0.8269;
  transform: translate(65.2734vw, -10px) scale(0.7056);
  animation: fall-34 16s -30s linear infinite;
}

@keyframes fall-34 {
  66.689% {
    transform: translate(58.6139vw, 66.689vh) scale(0.7056);
  }

  to {
    transform: translate(61.94365vw, 100vh) scale(0.7056);
  }
}

.snow:nth-child(35) {
  opacity: 0.5161;
  transform: translate(98.2079vw, -10px) scale(0.2231);
  animation: fall-35 24s -9s linear infinite;
}

@keyframes fall-35 {
  70.542% {
    transform: translate(94.9734vw, 70.542vh) scale(0.2231);
  }

  to {
    transform: translate(96.59065vw, 100vh) scale(0.2231);
  }
}

.snow:nth-child(36) {
  opacity: 0.9744;
  transform: translate(76.4365vw, -10px) scale(0.7106);
  animation: fall-36 10s -30s linear infinite;
}

@keyframes fall-36 {
  43.151% {
    transform: translate(82.4572vw, 43.151vh) scale(0.7106);
  }

  to {
    transform: translate(79.44685vw, 100vh) scale(0.7106);
  }
}

.snow:nth-child(37) {
  opacity: 0.263;
  transform: translate(46.6252vw, -10px) scale(0.0349);
  animation: fall-37 21s -12s linear infinite;
}

@keyframes fall-37 {
  37.299% {
    transform: translate(50.2656vw, 37.299vh) scale(0.0349);
  }

  to {
    transform: translate(48.4454vw, 100vh) scale(0.0349);
  }
}

.snow:nth-child(38) {
  opacity: 0.5085;
  transform: translate(43.4212vw, -10px) scale(0.4739);
  animation: fall-38 18s -3s linear infinite;
}

@keyframes fall-38 {
  43.413% {
    transform: translate(33.8367vw, 43.413vh) scale(0.4739);
  }

  to {
    transform: translate(38.62895vw, 100vh) scale(0.4739);
  }
}

.snow:nth-child(39) {
  opacity: 0.0709;
  transform: translate(50.4752vw, -10px) scale(0.5472);
  animation: fall-39 21s -12s linear infinite;
}

@keyframes fall-39 {
  69.557% {
    transform: translate(59.5554vw, 69.557vh) scale(0.5472);
  }

  to {
    transform: translate(55.0153vw, 100vh) scale(0.5472);
  }
}

.snow:nth-child(40) {
  opacity: 0.0784;
  transform: translate(54.104vw, -10px) scale(0.9488);
  animation: fall-40 12s -30s linear infinite;
}

@keyframes fall-40 {
  40.237% {
    transform: translate(54.7231vw, 40.237vh) scale(0.9488);
  }

  to {
    transform: translate(54.41355vw, 100vh) scale(0.9488);
  }
}

.snow:nth-child(41) {
  opacity: 0.5702;
  transform: translate(32.2163vw, -10px) scale(0.3264);
  animation: fall-41 11s -21s linear infinite;
}

@keyframes fall-41 {
  33.385% {
    transform: translate(24.9619vw, 33.385vh) scale(0.3264);
  }

  to {
    transform: translate(28.5891vw, 100vh) scale(0.3264);
  }
}

.snow:nth-child(42) {
  opacity: 0.2298;
  transform: translate(91.9471vw, -10px) scale(0.6443);
  animation: fall-42 28s -4s linear infinite;
}

@keyframes fall-42 {
  60.77% {
    transform: translate(95.4103vw, 60.77vh) scale(0.6443);
  }

  to {
    transform: translate(93.6787vw, 100vh) scale(0.6443);
  }
}

.snow:nth-child(43) {
  opacity: 0.3942;
  transform: translate(13.7914vw, -10px) scale(0.2654);
  animation: fall-43 10s -14s linear infinite;
}

@keyframes fall-43 {
  41.88% {
    transform: translate(10.8477vw, 41.88vh) scale(0.2654);
  }

  to {
    transform: translate(12.31955vw, 100vh) scale(0.2654);
  }
}

.snow:nth-child(44) {
  opacity: 0.4968;
  transform: translate(46.9166vw, -10px) scale(0.5305);
  animation: fall-44 19s -21s linear infinite;
}

@keyframes fall-44 {
  75.821% {
    transform: translate(43.5901vw, 75.821vh) scale(0.5305);
  }

  to {
    transform: translate(45.25335vw, 100vh) scale(0.5305);
  }
}

.snow:nth-child(45) {
  opacity: 0.4697;
  transform: translate(56.0514vw, -10px) scale(0.6433);
  animation: fall-45 30s -8s linear infinite;
}

@keyframes fall-45 {
  60.541% {
    transform: translate(60.1817vw, 60.541vh) scale(0.6433);
  }

  to {
    transform: translate(58.11655vw, 100vh) scale(0.6433);
  }
}

.snow:nth-child(46) {
  opacity: 0.6027;
  transform: translate(55.3109vw, -10px) scale(0.8009);
  animation: fall-46 13s -9s linear infinite;
}

@keyframes fall-46 {
  36.693% {
    transform: translate(60.0254vw, 36.693vh) scale(0.8009);
  }

  to {
    transform: translate(57.66815vw, 100vh) scale(0.8009);
  }
}

.snow:nth-child(47) {
  opacity: 0.1747;
  transform: translate(16.6714vw, -10px) scale(0.5188);
  animation: fall-47 18s -20s linear infinite;
}

@keyframes fall-47 {
  59.313% {
    transform: translate(19.5553vw, 59.313vh) scale(0.5188);
  }

  to {
    transform: translate(18.11335vw, 100vh) scale(0.5188);
  }
}

.snow:nth-child(48) {
  opacity: 0.5475;
  transform: translate(99.4082vw, -10px) scale(0.9563);
  animation: fall-48 19s -21s linear infinite;
}

@keyframes fall-48 {
  47.779% {
    transform: translate(90.1031vw, 47.779vh) scale(0.9563);
  }

  to {
    transform: translate(94.75565vw, 100vh) scale(0.9563);
  }
}

.snow:nth-child(49) {
  opacity: 0.9847;
  transform: translate(75.7926vw, -10px) scale(0.8487);
  animation: fall-49 22s -15s linear infinite;
}

@keyframes fall-49 {
  54.902% {
    transform: translate(67.9124vw, 54.902vh) scale(0.8487);
  }

  to {
    transform: translate(71.8525vw, 100vh) scale(0.8487);
  }
}

.snow:nth-child(50) {
  opacity: 0.806;
  transform: translate(76.4898vw, -10px) scale(0.06);
  animation: fall-50 25s -16s linear infinite;
}

@keyframes fall-50 {
  34.375% {
    transform: translate(76.0743vw, 34.375vh) scale(0.06);
  }

  to {
    transform: translate(76.28205vw, 100vh) scale(0.06);
  }
}

.snow:nth-child(51) {
  opacity: 0.3981;
  transform: translate(15.0959vw, -10px) scale(0.1718);
  animation: fall-51 29s -23s linear infinite;
}

@keyframes fall-51 {
  59.803% {
    transform: translate(8.9424vw, 59.803vh) scale(0.1718);
  }

  to {
    transform: translate(12.01915vw, 100vh) scale(0.1718);
  }
}

.snow:nth-child(52) {
  opacity: 0.7232;
  transform: translate(18.4093vw, -10px) scale(0.037);
  animation: fall-52 24s -4s linear infinite;
}

@keyframes fall-52 {
  76.435% {
    transform: translate(20.2257vw, 76.435vh) scale(0.037);
  }

  to {
    transform: translate(19.3175vw, 100vh) scale(0.037);
  }
}

.snow:nth-child(53) {
  opacity: 0.8585;
  transform: translate(26.2093vw, -10px) scale(0.8684);
  animation: fall-53 19s -23s linear infinite;
}

@keyframes fall-53 {
  54.323% {
    transform: translate(35.2978vw, 54.323vh) scale(0.8684);
  }

  to {
    transform: translate(30.75355vw, 100vh) scale(0.8684);
  }
}

.snow:nth-child(54) {
  opacity: 0.8981;
  transform: translate(24.0145vw, -10px) scale(0.3292);
  animation: fall-54 14s -21s linear infinite;
}

@keyframes fall-54 {
  65.784% {
    transform: translate(21.8518vw, 65.784vh) scale(0.3292);
  }

  to {
    transform: translate(22.93315vw, 100vh) scale(0.3292);
  }
}

.snow:nth-child(55) {
  opacity: 0.5082;
  transform: translate(91.874vw, -10px) scale(0.2868);
  animation: fall-55 14s -23s linear infinite;
}

@keyframes fall-55 {
  33.298% {
    transform: translate(101.3263vw, 33.298vh) scale(0.2868);
  }

  to {
    transform: translate(96.60015vw, 100vh) scale(0.2868);
  }
}

.snow:nth-child(56) {
  opacity: 0.2711;
  transform: translate(58.734vw, -10px) scale(0.5957);
  animation: fall-56 25s -28s linear infinite;
}

@keyframes fall-56 {
  72.457% {
    transform: translate(56.917vw, 72.457vh) scale(0.5957);
  }

  to {
    transform: translate(57.8255vw, 100vh) scale(0.5957);
  }
}

.snow:nth-child(57) {
  opacity: 0.8821;
  transform: translate(12.3761vw, -10px) scale(0.1745);
  animation: fall-57 23s -2s linear infinite;
}

@keyframes fall-57 {
  62.943% {
    transform: translate(18.2378vw, 62.943vh) scale(0.1745);
  }

  to {
    transform: translate(15.30695vw, 100vh) scale(0.1745);
  }
}

.snow:nth-child(58) {
  opacity: 0.5608;
  transform: translate(28.3841vw, -10px) scale(0.8343);
  animation: fall-58 18s -4s linear infinite;
}

@keyframes fall-58 {
  30.589% {
    transform: translate(31.49vw, 30.589vh) scale(0.8343);
  }

  to {
    transform: translate(29.93705vw, 100vh) scale(0.8343);
  }
}

.snow:nth-child(59) {
  opacity: 0.5179;
  transform: translate(34.5242vw, -10px) scale(0.1038);
  animation: fall-59 17s -4s linear infinite;
}

@keyframes fall-59 {
  71.661% {
    transform: translate(31.0989vw, 71.661vh) scale(0.1038);
  }

  to {
    transform: translate(32.81155vw, 100vh) scale(0.1038);
  }
}

.snow:nth-child(60) {
  opacity: 0.5613;
  transform: translate(80.3444vw, -10px) scale(0.0841);
  animation: fall-60 19s -12s linear infinite;
}

@keyframes fall-60 {
  44.708% {
    transform: translate(86.2664vw, 44.708vh) scale(0.0841);
  }

  to {
    transform: translate(83.3054vw, 100vh) scale(0.0841);
  }
}

.snow:nth-child(61) {
  opacity: 0.49;
  transform: translate(70.1077vw, -10px) scale(0.0539);
  animation: fall-61 18s -27s linear infinite;
}

@keyframes fall-61 {
  76.912% {
    transform: translate(71.7406vw, 76.912vh) scale(0.0539);
  }

  to {
    transform: translate(70.92415vw, 100vh) scale(0.0539);
  }
}

.snow:nth-child(62) {
  opacity: 0.3069;
  transform: translate(88.0992vw, -10px) scale(0.0307);
  animation: fall-62 25s -17s linear infinite;
}

@keyframes fall-62 {
  76.833% {
    transform: translate(80.7432vw, 76.833vh) scale(0.0307);
  }

  to {
    transform: translate(84.4212vw, 100vh) scale(0.0307);
  }
}

.snow:nth-child(63) {
  opacity: 0.844;
  transform: translate(32.065vw, -10px) scale(0.0882);
  animation: fall-63 12s -5s linear infinite;
}

@keyframes fall-63 {
  32.385% {
    transform: translate(26.9835vw, 32.385vh) scale(0.0882);
  }

  to {
    transform: translate(29.52425vw, 100vh) scale(0.0882);
  }
}

.snow:nth-child(64) {
  opacity: 0.5392;
  transform: translate(28.5536vw, -10px) scale(0.8485);
  animation: fall-64 20s -11s linear infinite;
}

@keyframes fall-64 {
  51.383% {
    transform: translate(22.2055vw, 51.383vh) scale(0.8485);
  }

  to {
    transform: translate(25.37955vw, 100vh) scale(0.8485);
  }
}

.snow:nth-child(65) {
  opacity: 0.8766;
  transform: translate(51.6411vw, -10px) scale(0.5185);
  animation: fall-65 20s -18s linear infinite;
}

@keyframes fall-65 {
  61.344% {
    transform: translate(43.4464vw, 61.344vh) scale(0.5185);
  }

  to {
    transform: translate(47.54375vw, 100vh) scale(0.5185);
  }
}

.snow:nth-child(66) {
  opacity: 0.0397;
  transform: translate(27.632vw, -10px) scale(0.7792);
  animation: fall-66 12s -30s linear infinite;
}

@keyframes fall-66 {
  73.637% {
    transform: translate(19.7881vw, 73.637vh) scale(0.7792);
  }

  to {
    transform: translate(23.71005vw, 100vh) scale(0.7792);
  }
}

.snow:nth-child(67) {
  opacity: 0.4436;
  transform: translate(26.1913vw, -10px) scale(0.7893);
  animation: fall-67 24s -25s linear infinite;
}

@keyframes fall-67 {
  50.59% {
    transform: translate(28.7125vw, 50.59vh) scale(0.7893);
  }

  to {
    transform: translate(27.4519vw, 100vh) scale(0.7893);
  }
}

.snow:nth-child(68) {
  opacity: 0.6752;
  transform: translate(76.3673vw, -10px) scale(0.8854);
  animation: fall-68 26s -29s linear infinite;
}

@keyframes fall-68 {
  44.652% {
    transform: translate(74.2047vw, 44.652vh) scale(0.8854);
  }

  to {
    transform: translate(75.286vw, 100vh) scale(0.8854);
  }
}

.snow:nth-child(69) {
  opacity: 0.7911;
  transform: translate(66.1265vw, -10px) scale(0.5072);
  animation: fall-69 20s -22s linear infinite;
}

@keyframes fall-69 {
  57.417% {
    transform: translate(70.5621vw, 57.417vh) scale(0.5072);
  }

  to {
    transform: translate(68.3443vw, 100vh) scale(0.5072);
  }
}

.snow:nth-child(70) {
  opacity: 0.0115;
  transform: translate(70.1945vw, -10px) scale(0.0191);
  animation: fall-70 17s -21s linear infinite;
}

@keyframes fall-70 {
  35.285% {
    transform: translate(66.6657vw, 35.285vh) scale(0.0191);
  }

  to {
    transform: translate(68.4301vw, 100vh) scale(0.0191);
  }
}

.snow:nth-child(71) {
  opacity: 0.7595;
  transform: translate(48.795vw, -10px) scale(0.0312);
  animation: fall-71 28s -18s linear infinite;
}

@keyframes fall-71 {
  63.37% {
    transform: translate(51.9114vw, 63.37vh) scale(0.0312);
  }

  to {
    transform: translate(50.3532vw, 100vh) scale(0.0312);
  }
}

.snow:nth-child(72) {
  opacity: 0.5689;
  transform: translate(36.8384vw, -10px) scale(0.068);
  animation: fall-72 14s -7s linear infinite;
}

@keyframes fall-72 {
  79.42% {
    transform: translate(31.8939vw, 79.42vh) scale(0.068);
  }

  to {
    transform: translate(34.36615vw, 100vh) scale(0.068);
  }
}

.snow:nth-child(73) {
  opacity: 0.4571;
  transform: translate(24.2704vw, -10px) scale(0.4106);
  animation: fall-73 29s -14s linear infinite;
}

@keyframes fall-73 {
  66.933% {
    transform: translate(32.1372vw, 66.933vh) scale(0.4106);
  }

  to {
    transform: translate(28.2038vw, 100vh) scale(0.4106);
  }
}

.snow:nth-child(74) {
  opacity: 0.7491;
  transform: translate(1.7363vw, -10px) scale(0.0809);
  animation: fall-74 13s -5s linear infinite;
}

@keyframes fall-74 {
  62.85% {
    transform: translate(-3.9034vw, 62.85vh) scale(0.0809);
  }

  to {
    transform: translate(-1.08355vw, 100vh) scale(0.0809);
  }
}

.snow:nth-child(75) {
  opacity: 0.69;
  transform: translate(22.5156vw, -10px) scale(0.9322);
  animation: fall-75 18s -23s linear infinite;
}

@keyframes fall-75 {
  47.22% {
    transform: translate(24.2059vw, 47.22vh) scale(0.9322);
  }

  to {
    transform: translate(23.36075vw, 100vh) scale(0.9322);
  }
}

.snow:nth-child(76) {
  opacity: 0.8558;
  transform: translate(93.3307vw, -10px) scale(0.1785);
  animation: fall-76 26s -30s linear infinite;
}

@keyframes fall-76 {
  68.773% {
    transform: translate(100.8793vw, 68.773vh) scale(0.1785);
  }

  to {
    transform: translate(97.105vw, 100vh) scale(0.1785);
  }
}

.snow:nth-child(77) {
  opacity: 0.8467;
  transform: translate(15.7001vw, -10px) scale(0.3242);
  animation: fall-77 19s -6s linear infinite;
}

@keyframes fall-77 {
  33.351% {
    transform: translate(23.458vw, 33.351vh) scale(0.3242);
  }

  to {
    transform: translate(19.57905vw, 100vh) scale(0.3242);
  }
}

.snow:nth-child(78) {
  opacity: 0.8853;
  transform: translate(27.8721vw, -10px) scale(0.5393);
  animation: fall-78 20s -14s linear infinite;
}

@keyframes fall-78 {
  50.548% {
    transform: translate(28.824vw, 50.548vh) scale(0.5393);
  }

  to {
    transform: translate(28.34805vw, 100vh) scale(0.5393);
  }
}

.snow:nth-child(79) {
  opacity: 0.5232;
  transform: translate(87.8068vw, -10px) scale(0.0036);
  animation: fall-79 25s -23s linear infinite;
}

@keyframes fall-79 {
  58.788% {
    transform: translate(82.2281vw, 58.788vh) scale(0.0036);
  }

  to {
    transform: translate(85.01745vw, 100vh) scale(0.0036);
  }
}

.snow:nth-child(80) {
  opacity: 0.3548;
  transform: translate(99.0772vw, -10px) scale(0.9287);
  animation: fall-80 28s -4s linear infinite;
}

@keyframes fall-80 {
  31.952% {
    transform: translate(89.2763vw, 31.952vh) scale(0.9287);
  }

  to {
    transform: translate(94.17675vw, 100vh) scale(0.9287);
  }
}

.snow:nth-child(81) {
  opacity: 0.7724;
  transform: translate(37.6299vw, -10px) scale(0.6767);
  animation: fall-81 27s -24s linear infinite;
}

@keyframes fall-81 {
  61.396% {
    transform: translate(39.7536vw, 61.396vh) scale(0.6767);
  }

  to {
    transform: translate(38.69175vw, 100vh) scale(0.6767);
  }
}

.snow:nth-child(82) {
  opacity: 0.8641;
  transform: translate(14.0058vw, -10px) scale(0.1051);
  animation: fall-82 15s -4s linear infinite;
}

@keyframes fall-82 {
  78.835% {
    transform: translate(15.1962vw, 78.835vh) scale(0.1051);
  }

  to {
    transform: translate(14.601vw, 100vh) scale(0.1051);
  }
}

.snow:nth-child(83) {
  opacity: 0.5275;
  transform: translate(72.8204vw, -10px) scale(0.121);
  animation: fall-83 15s -10s linear infinite;
}

@keyframes fall-83 {
  67.253% {
    transform: translate(64.9355vw, 67.253vh) scale(0.121);
  }

  to {
    transform: translate(68.87795vw, 100vh) scale(0.121);
  }
}

.snow:nth-child(84) {
  opacity: 0.1598;
  transform: translate(47.4815vw, -10px) scale(0.7111);
  animation: fall-84 16s -28s linear infinite;
}

@keyframes fall-84 {
  30.528% {
    transform: translate(51.1746vw, 30.528vh) scale(0.7111);
  }

  to {
    transform: translate(49.32805vw, 100vh) scale(0.7111);
  }
}

.snow:nth-child(85) {
  opacity: 0.7248;
  transform: translate(16.9708vw, -10px) scale(0.6189);
  animation: fall-85 18s -23s linear infinite;
}

@keyframes fall-85 {
  33.196% {
    transform: translate(7.2012vw, 33.196vh) scale(0.6189);
  }

  to {
    transform: translate(12.086vw, 100vh) scale(0.6189);
  }
}

.snow:nth-child(86) {
  opacity: 0.9706;
  transform: translate(72.4717vw, -10px) scale(0.1669);
  animation: fall-86 21s -26s linear infinite;
}

@keyframes fall-86 {
  54.997% {
    transform: translate(63.9204vw, 54.997vh) scale(0.1669);
  }

  to {
    transform: translate(68.19605vw, 100vh) scale(0.1669);
  }
}

.snow:nth-child(87) {
  opacity: 0.216;
  transform: translate(12.5715vw, -10px) scale(0.0483);
  animation: fall-87 25s -4s linear infinite;
}

@keyframes fall-87 {
  61.659% {
    transform: translate(13.6502vw, 61.659vh) scale(0.0483);
  }

  to {
    transform: translate(13.11085vw, 100vh) scale(0.0483);
  }
}

.snow:nth-child(88) {
  opacity: 0.4682;
  transform: translate(65.4794vw, -10px) scale(0.6499);
  animation: fall-88 11s -26s linear infinite;
}

@keyframes fall-88 {
  46.158% {
    transform: translate(59.4611vw, 46.158vh) scale(0.6499);
  }

  to {
    transform: translate(62.47025vw, 100vh) scale(0.6499);
  }
}

.snow:nth-child(89) {
  opacity: 0.4698;
  transform: translate(94.3335vw, -10px) scale(0.0198);
  animation: fall-89 20s -15s linear infinite;
}

@keyframes fall-89 {
  56.869% {
    transform: translate(90.0068vw, 56.869vh) scale(0.0198);
  }

  to {
    transform: translate(92.17015vw, 100vh) scale(0.0198);
  }
}

.snow:nth-child(90) {
  opacity: 0.1845;
  transform: translate(60.2284vw, -10px) scale(0.6502);
  animation: fall-90 25s -7s linear infinite;
}

@keyframes fall-90 {
  78.24% {
    transform: translate(59.5917vw, 78.24vh) scale(0.6502);
  }

  to {
    transform: translate(59.91005vw, 100vh) scale(0.6502);
  }
}

.snow:nth-child(91) {
  opacity: 0.7058;
  transform: translate(6.5278vw, -10px) scale(0.1044);
  animation: fall-91 27s -9s linear infinite;
}

@keyframes fall-91 {
  68.102% {
    transform: translate(12.7563vw, 68.102vh) scale(0.1044);
  }

  to {
    transform: translate(9.64205vw, 100vh) scale(0.1044);
  }
}

.snow:nth-child(92) {
  opacity: 0.4204;
  transform: translate(1.6285vw, -10px) scale(0.1334);
  animation: fall-92 15s -1s linear infinite;
}

@keyframes fall-92 {
  41.232% {
    transform: translate(6.3871vw, 41.232vh) scale(0.1334);
  }

  to {
    transform: translate(4.0078vw, 100vh) scale(0.1334);
  }
}

.snow:nth-child(93) {
  opacity: 0.6027;
  transform: translate(40.8408vw, -10px) scale(0.1763);
  animation: fall-93 12s -17s linear infinite;
}

@keyframes fall-93 {
  64.653% {
    transform: translate(47.7965vw, 64.653vh) scale(0.1763);
  }

  to {
    transform: translate(44.31865vw, 100vh) scale(0.1763);
  }
}

.snow:nth-child(94) {
  opacity: 0.7239;
  transform: translate(34.3309vw, -10px) scale(0.558);
  animation: fall-94 22s -27s linear infinite;
}

@keyframes fall-94 {
  55.044% {
    transform: translate(37.9613vw, 55.044vh) scale(0.558);
  }

  to {
    transform: translate(36.1461vw, 100vh) scale(0.558);
  }
}

.snow:nth-child(95) {
  opacity: 0.8691;
  transform: translate(97.8055vw, -10px) scale(0.6124);
  animation: fall-95 10s -6s linear infinite;
}

@keyframes fall-95 {
  65.209% {
    transform: translate(102.5015vw, 65.209vh) scale(0.6124);
  }

  to {
    transform: translate(100.1535vw, 100vh) scale(0.6124);
  }
}

.snow:nth-child(96) {
  opacity: 0.7782;
  transform: translate(70.647vw, -10px) scale(0.156);
  animation: fall-96 12s -18s linear infinite;
}

@keyframes fall-96 {
  30.64% {
    transform: translate(63.7852vw, 30.64vh) scale(0.156);
  }

  to {
    transform: translate(67.2161vw, 100vh) scale(0.156);
  }
}

.snow:nth-child(97) {
  opacity: 0.9086;
  transform: translate(77.908vw, -10px) scale(0.8666);
  animation: fall-97 19s -14s linear infinite;
}

@keyframes fall-97 {
  58.722% {
    transform: translate(82.5641vw, 58.722vh) scale(0.8666);
  }

  to {
    transform: translate(80.23605vw, 100vh) scale(0.8666);
  }
}

.snow:nth-child(98) {
  opacity: 0.6683;
  transform: translate(15.7166vw, -10px) scale(0.129);
  animation: fall-98 13s -29s linear infinite;
}

@keyframes fall-98 {
  32.482% {
    transform: translate(14.6253vw, 32.482vh) scale(0.129);
  }

  to {
    transform: translate(15.17095vw, 100vh) scale(0.129);
  }
}

.snow:nth-child(99) {
  opacity: 0.7859;
  transform: translate(7.8185vw, -10px) scale(0.2721);
  animation: fall-99 29s -21s linear infinite;
}

@keyframes fall-99 {
  47.329% {
    transform: translate(6.3711vw, 47.329vh) scale(0.2721);
  }

  to {
    transform: translate(7.0948vw, 100vh) scale(0.2721);
  }
}

.snow:nth-child(100) {
  opacity: 0.5438;
  transform: translate(17.3443vw, -10px) scale(0.7272);
  animation: fall-100 28s -18s linear infinite;
}

@keyframes fall-100 {
  67.533% {
    transform: translate(24.5763vw, 67.533vh) scale(0.7272);
  }

  to {
    transform: translate(20.9603vw, 100vh) scale(0.7272);
  }
}

.snow:nth-child(101) {
  opacity: 0.3195;
  transform: translate(47.1968vw, -10px) scale(0.1313);
  animation: fall-101 15s -4s linear infinite;
}

@keyframes fall-101 {
  77.874% {
    transform: translate(48.7897vw, 77.874vh) scale(0.1313);
  }

  to {
    transform: translate(47.99325vw, 100vh) scale(0.1313);
  }
}

.snow:nth-child(102) {
  opacity: 0.7839;
  transform: translate(43.1616vw, -10px) scale(0.1202);
  animation: fall-102 29s -15s linear infinite;
}

@keyframes fall-102 {
  38.043% {
    transform: translate(40.629vw, 38.043vh) scale(0.1202);
  }

  to {
    transform: translate(41.8953vw, 100vh) scale(0.1202);
  }
}

.snow:nth-child(103) {
  opacity: 0.0327;
  transform: translate(2.6176vw, -10px) scale(0.1711);
  animation: fall-103 23s -7s linear infinite;
}

@keyframes fall-103 {
  39.985% {
    transform: translate(11.9421vw, 39.985vh) scale(0.1711);
  }

  to {
    transform: translate(7.27985vw, 100vh) scale(0.1711);
  }
}

.snow:nth-child(104) {
  opacity: 0.4404;
  transform: translate(37.3057vw, -10px) scale(0.2233);
  animation: fall-104 28s -27s linear infinite;
}

@keyframes fall-104 {
  59.071% {
    transform: translate(34.9318vw, 59.071vh) scale(0.2233);
  }

  to {
    transform: translate(36.11875vw, 100vh) scale(0.2233);
  }
}

.snow:nth-child(105) {
  opacity: 0.4474;
  transform: translate(12.8189vw, -10px) scale(0.5708);
  animation: fall-105 22s -10s linear infinite;
}

@keyframes fall-105 {
  69.318% {
    transform: translate(12.3038vw, 69.318vh) scale(0.5708);
  }

  to {
    transform: translate(12.56135vw, 100vh) scale(0.5708);
  }
}

.snow:nth-child(106) {
  opacity: 0.0829;
  transform: translate(0.4019vw, -10px) scale(0.2895);
  animation: fall-106 29s -8s linear infinite;
}

@keyframes fall-106 {
  71.7% {
    transform: translate(8.3243vw, 71.7vh) scale(0.2895);
  }

  to {
    transform: translate(4.3631vw, 100vh) scale(0.2895);
  }
}

.snow:nth-child(107) {
  opacity: 0.6656;
  transform: translate(78.4828vw, -10px) scale(0.6512);
  animation: fall-107 11s -25s linear infinite;
}

@keyframes fall-107 {
  49.632% {
    transform: translate(85.5902vw, 49.632vh) scale(0.6512);
  }

  to {
    transform: translate(82.0365vw, 100vh) scale(0.6512);
  }
}

.snow:nth-child(108) {
  opacity: 0.1294;
  transform: translate(69.5636vw, -10px) scale(0.7478);
  animation: fall-108 22s -24s linear infinite;
}

@keyframes fall-108 {
  63.909% {
    transform: translate(76.8824vw, 63.909vh) scale(0.7478);
  }

  to {
    transform: translate(73.223vw, 100vh) scale(0.7478);
  }
}

.snow:nth-child(109) {
  opacity: 0.2051;
  transform: translate(11.3995vw, -10px) scale(0.6247);
  animation: fall-109 14s -11s linear infinite;
}

@keyframes fall-109 {
  68.138% {
    transform: translate(20.704vw, 68.138vh) scale(0.6247);
  }

  to {
    transform: translate(16.05175vw, 100vh) scale(0.6247);
  }
}

.snow:nth-child(110) {
  opacity: 0.4017;
  transform: translate(4.6379vw, -10px) scale(0.4951);
  animation: fall-110 16s -19s linear infinite;
}

@keyframes fall-110 {
  40.981% {
    transform: translate(-4.6642vw, 40.981vh) scale(0.4951);
  }

  to {
    transform: translate(-0.01315vw, 100vh) scale(0.4951);
  }
}

.snow:nth-child(111) {
  opacity: 0.3044;
  transform: translate(81.2098vw, -10px) scale(0.6771);
  animation: fall-111 26s -11s linear infinite;
}

@keyframes fall-111 {
  39.729% {
    transform: translate(71.3625vw, 39.729vh) scale(0.6771);
  }

  to {
    transform: translate(76.28615vw, 100vh) scale(0.6771);
  }
}

.snow:nth-child(112) {
  opacity: 0.1558;
  transform: translate(82.7753vw, -10px) scale(0.0093);
  animation: fall-112 15s -16s linear infinite;
}

@keyframes fall-112 {
  72.23% {
    transform: translate(90.8584vw, 72.23vh) scale(0.0093);
  }

  to {
    transform: translate(86.81685vw, 100vh) scale(0.0093);
  }
}

.snow:nth-child(113) {
  opacity: 0.2258;
  transform: translate(82.3402vw, -10px) scale(0.4302);
  animation: fall-113 22s -21s linear infinite;
}

@keyframes fall-113 {
  41.733% {
    transform: translate(86.6876vw, 41.733vh) scale(0.4302);
  }

  to {
    transform: translate(84.5139vw, 100vh) scale(0.4302);
  }
}

.snow:nth-child(114) {
  opacity: 0.8435;
  transform: translate(11.6295vw, -10px) scale(0.8923);
  animation: fall-114 10s -12s linear infinite;
}

@keyframes fall-114 {
  64.963% {
    transform: translate(17.0368vw, 64.963vh) scale(0.8923);
  }

  to {
    transform: translate(14.33315vw, 100vh) scale(0.8923);
  }
}

.snow:nth-child(115) {
  opacity: 0.2782;
  transform: translate(95.0318vw, -10px) scale(0.5103);
  animation: fall-115 16s -8s linear infinite;
}

@keyframes fall-115 {
  37.001% {
    transform: translate(94.2938vw, 37.001vh) scale(0.5103);
  }

  to {
    transform: translate(94.6628vw, 100vh) scale(0.5103);
  }
}

.snow:nth-child(116) {
  opacity: 0.499;
  transform: translate(22.176vw, -10px) scale(0.3283);
  animation: fall-116 13s -7s linear infinite;
}

@keyframes fall-116 {
  30.413% {
    transform: translate(15.4241vw, 30.413vh) scale(0.3283);
  }

  to {
    transform: translate(18.80005vw, 100vh) scale(0.3283);
  }
}

.snow:nth-child(117) {
  opacity: 0.5409;
  transform: translate(30.0242vw, -10px) scale(0.961);
  animation: fall-117 10s -15s linear infinite;
}

@keyframes fall-117 {
  74.37% {
    transform: translate(28.7662vw, 74.37vh) scale(0.961);
  }

  to {
    transform: translate(29.3952vw, 100vh) scale(0.961);
  }
}

.snow:nth-child(118) {
  opacity: 0.095;
  transform: translate(87.3643vw, -10px) scale(0.8065);
  animation: fall-118 27s -12s linear infinite;
}

@keyframes fall-118 {
  51.331% {
    transform: translate(80.3386vw, 51.331vh) scale(0.8065);
  }

  to {
    transform: translate(83.85145vw, 100vh) scale(0.8065);
  }
}

.snow:nth-child(119) {
  opacity: 0.207;
  transform: translate(43.766vw, -10px) scale(0.1747);
  animation: fall-119 27s -24s linear infinite;
}

@keyframes fall-119 {
  53.898% {
    transform: translate(36.8337vw, 53.898vh) scale(0.1747);
  }

  to {
    transform: translate(40.29985vw, 100vh) scale(0.1747);
  }
}

.snow:nth-child(120) {
  opacity: 0.322;
  transform: translate(99.1595vw, -10px) scale(0.6649);
  animation: fall-120 28s -18s linear infinite;
}

@keyframes fall-120 {
  64.166% {
    transform: translate(96.6491vw, 64.166vh) scale(0.6649);
  }

  to {
    transform: translate(97.9043vw, 100vh) scale(0.6649);
  }
}

.snow:nth-child(121) {
  opacity: 0.1053;
  transform: translate(50.5715vw, -10px) scale(0.4885);
  animation: fall-121 17s -26s linear infinite;
}

@keyframes fall-121 {
  71.114% {
    transform: translate(56.9487vw, 71.114vh) scale(0.4885);
  }

  to {
    transform: translate(53.7601vw, 100vh) scale(0.4885);
  }
}

.snow:nth-child(122) {
  opacity: 0.94;
  transform: translate(84.5218vw, -10px) scale(0.8027);
  animation: fall-122 15s -4s linear infinite;
}

@keyframes fall-122 {
  57.038% {
    transform: translate(81.3579vw, 57.038vh) scale(0.8027);
  }

  to {
    transform: translate(82.93985vw, 100vh) scale(0.8027);
  }
}

.snow:nth-child(123) {
  opacity: 0.254;
  transform: translate(64.4685vw, -10px) scale(0.7211);
  animation: fall-123 17s -25s linear infinite;
}

@keyframes fall-123 {
  58.102% {
    transform: translate(67.638vw, 58.102vh) scale(0.7211);
  }

  to {
    transform: translate(66.05325vw, 100vh) scale(0.7211);
  }
}

.snow:nth-child(124) {
  opacity: 0.6979;
  transform: translate(53.3911vw, -10px) scale(0.8294);
  animation: fall-124 26s -23s linear infinite;
}

@keyframes fall-124 {
  61.891% {
    transform: translate(62.6042vw, 61.891vh) scale(0.8294);
  }

  to {
    transform: translate(57.99765vw, 100vh) scale(0.8294);
  }
}

.snow:nth-child(125) {
  opacity: 0.0679;
  transform: translate(74.5241vw, -10px) scale(0.5624);
  animation: fall-125 23s -20s linear infinite;
}

@keyframes fall-125 {
  35.268% {
    transform: translate(69.7399vw, 35.268vh) scale(0.5624);
  }

  to {
    transform: translate(72.132vw, 100vh) scale(0.5624);
  }
}

.snow:nth-child(126) {
  opacity: 0.2338;
  transform: translate(49.9001vw, -10px) scale(0.8704);
  animation: fall-126 29s -26s linear infinite;
}

@keyframes fall-126 {
  44.661% {
    transform: translate(57.404vw, 44.661vh) scale(0.8704);
  }

  to {
    transform: translate(53.65205vw, 100vh) scale(0.8704);
  }
}

.snow:nth-child(127) {
  opacity: 0.1277;
  transform: translate(3.7019vw, -10px) scale(0.874);
  animation: fall-127 23s -23s linear infinite;
}

@keyframes fall-127 {
  78.387% {
    transform: translate(-0.4749vw, 78.387vh) scale(0.874);
  }

  to {
    transform: translate(1.6135vw, 100vh) scale(0.874);
  }
}

.snow:nth-child(128) {
  opacity: 0.4785;
  transform: translate(74.699vw, -10px) scale(0.0634);
  animation: fall-128 12s -26s linear infinite;
}

@keyframes fall-128 {
  42.822% {
    transform: translate(81.4095vw, 42.822vh) scale(0.0634);
  }

  to {
    transform: translate(78.05425vw, 100vh) scale(0.0634);
  }
}

.snow:nth-child(129) {
  opacity: 0.6917;
  transform: translate(83.2849vw, -10px) scale(0.5409);
  animation: fall-129 22s -3s linear infinite;
}

@keyframes fall-129 {
  34.907% {
    transform: translate(74.6251vw, 34.907vh) scale(0.5409);
  }

  to {
    transform: translate(78.955vw, 100vh) scale(0.5409);
  }
}

.snow:nth-child(130) {
  opacity: 0.2575;
  transform: translate(90.2458vw, -10px) scale(0.9425);
  animation: fall-130 12s -24s linear infinite;
}

@keyframes fall-130 {
  45.106% {
    transform: translate(91.5436vw, 45.106vh) scale(0.9425);
  }

  to {
    transform: translate(90.8947vw, 100vh) scale(0.9425);
  }
}

.snow:nth-child(131) {
  opacity: 0.4759;
  transform: translate(54.9262vw, -10px) scale(0.2094);
  animation: fall-131 14s -4s linear infinite;
}

@keyframes fall-131 {
  63.879% {
    transform: translate(49.9095vw, 63.879vh) scale(0.2094);
  }

  to {
    transform: translate(52.41785vw, 100vh) scale(0.2094);
  }
}

.snow:nth-child(132) {
  opacity: 0.9666;
  transform: translate(5.5723vw, -10px) scale(0.6873);
  animation: fall-132 20s -25s linear infinite;
}

@keyframes fall-132 {
  67.809% {
    transform: translate(-0.9295vw, 67.809vh) scale(0.6873);
  }

  to {
    transform: translate(2.3214vw, 100vh) scale(0.6873);
  }
}

.snow:nth-child(133) {
  opacity: 0.1515;
  transform: translate(3.299vw, -10px) scale(0.1497);
  animation: fall-133 26s -15s linear infinite;
}

@keyframes fall-133 {
  35.959% {
    transform: translate(3.1613vw, 35.959vh) scale(0.1497);
  }

  to {
    transform: translate(3.23015vw, 100vh) scale(0.1497);
  }
}

.snow:nth-child(134) {
  opacity: 0.131;
  transform: translate(98.5463vw, -10px) scale(0.8683);
  animation: fall-134 12s -17s linear infinite;
}

@keyframes fall-134 {
  41.932% {
    transform: translate(102.8314vw, 41.932vh) scale(0.8683);
  }

  to {
    transform: translate(100.68885vw, 100vh) scale(0.8683);
  }
}

.snow:nth-child(135) {
  opacity: 0.14;
  transform: translate(33.8323vw, -10px) scale(0.6632);
  animation: fall-135 28s -23s linear infinite;
}

@keyframes fall-135 {
  74.02% {
    transform: translate(42.3387vw, 74.02vh) scale(0.6632);
  }

  to {
    transform: translate(38.0855vw, 100vh) scale(0.6632);
  }
}

.snow:nth-child(136) {
  opacity: 0.4439;
  transform: translate(4.9278vw, -10px) scale(0.9438);
  animation: fall-136 28s -25s linear infinite;
}

@keyframes fall-136 {
  60.971% {
    transform: translate(9.9587vw, 60.971vh) scale(0.9438);
  }

  to {
    transform: translate(7.44325vw, 100vh) scale(0.9438);
  }
}

.snow:nth-child(137) {
  opacity: 0.1277;
  transform: translate(96.1417vw, -10px) scale(0.962);
  animation: fall-137 13s -19s linear infinite;
}

@keyframes fall-137 {
  68.279% {
    transform: translate(101.0608vw, 68.279vh) scale(0.962);
  }

  to {
    transform: translate(98.60125vw, 100vh) scale(0.962);
  }
}

.snow:nth-child(138) {
  opacity: 0.1584;
  transform: translate(89.7343vw, -10px) scale(0.5327);
  animation: fall-138 17s -23s linear infinite;
}

@keyframes fall-138 {
  38.623% {
    transform: translate(86.0845vw, 38.623vh) scale(0.5327);
  }

  to {
    transform: translate(87.9094vw, 100vh) scale(0.5327);
  }
}

.snow:nth-child(139) {
  opacity: 0.7487;
  transform: translate(64.5672vw, -10px) scale(0.0008);
  animation: fall-139 15s -22s linear infinite;
}

@keyframes fall-139 {
  62.393% {
    transform: translate(56.9633vw, 62.393vh) scale(0.0008);
  }

  to {
    transform: translate(60.76525vw, 100vh) scale(0.0008);
  }
}

.snow:nth-child(140) {
  opacity: 0.4557;
  transform: translate(4.8971vw, -10px) scale(0.8233);
  animation: fall-140 22s -10s linear infinite;
}

@keyframes fall-140 {
  34.414% {
    transform: translate(-0.5188vw, 34.414vh) scale(0.8233);
  }

  to {
    transform: translate(2.18915vw, 100vh) scale(0.8233);
  }
}

.snow:nth-child(141) {
  opacity: 0.9401;
  transform: translate(14.6446vw, -10px) scale(0.0416);
  animation: fall-141 15s -26s linear infinite;
}

@keyframes fall-141 {
  54.208% {
    transform: translate(10.5515vw, 54.208vh) scale(0.0416);
  }

  to {
    transform: translate(12.59805vw, 100vh) scale(0.0416);
  }
}

.snow:nth-child(142) {
  opacity: 0.6118;
  transform: translate(39.6155vw, -10px) scale(0.8618);
  animation: fall-142 21s -2s linear infinite;
}

@keyframes fall-142 {
  54.642% {
    transform: translate(43.3646vw, 54.642vh) scale(0.8618);
  }

  to {
    transform: translate(41.49005vw, 100vh) scale(0.8618);
  }
}

.snow:nth-child(143) {
  opacity: 0.296;
  transform: translate(40.6459vw, -10px) scale(0.9379);
  animation: fall-143 19s -2s linear infinite;
}

@keyframes fall-143 {
  60.797% {
    transform: translate(49.1251vw, 60.797vh) scale(0.9379);
  }

  to {
    transform: translate(44.8855vw, 100vh) scale(0.9379);
  }
}

.snow:nth-child(144) {
  opacity: 0.6312;
  transform: translate(40.3574vw, -10px) scale(0.5018);
  animation: fall-144 22s -7s linear infinite;
}

@keyframes fall-144 {
  34.364% {
    transform: translate(49.9521vw, 34.364vh) scale(0.5018);
  }

  to {
    transform: translate(45.15475vw, 100vh) scale(0.5018);
  }
}

.snow:nth-child(145) {
  opacity: 0.5041;
  transform: translate(58.907vw, -10px) scale(0.2308);
  animation: fall-145 30s -4s linear infinite;
}

@keyframes fall-145 {
  41.804% {
    transform: translate(53.9617vw, 41.804vh) scale(0.2308);
  }

  to {
    transform: translate(56.43435vw, 100vh) scale(0.2308);
  }
}

.snow:nth-child(146) {
  opacity: 0.5997;
  transform: translate(84.4747vw, -10px) scale(0.4046);
  animation: fall-146 11s -23s linear infinite;
}

@keyframes fall-146 {
  78.484% {
    transform: translate(79.3043vw, 78.484vh) scale(0.4046);
  }

  to {
    transform: translate(81.8895vw, 100vh) scale(0.4046);
  }
}

.snow:nth-child(147) {
  opacity: 0.5452;
  transform: translate(21.225vw, -10px) scale(0.684);
  animation: fall-147 27s -2s linear infinite;
}

@keyframes fall-147 {
  48.445% {
    transform: translate(17.022vw, 48.445vh) scale(0.684);
  }

  to {
    transform: translate(19.1235vw, 100vh) scale(0.684);
  }
}

.snow:nth-child(148) {
  opacity: 0.2594;
  transform: translate(97.1194vw, -10px) scale(0.8653);
  animation: fall-148 22s -17s linear infinite;
}

@keyframes fall-148 {
  67.111% {
    transform: translate(90.96vw, 67.111vh) scale(0.8653);
  }

  to {
    transform: translate(94.0397vw, 100vh) scale(0.8653);
  }
}

.snow:nth-child(149) {
  opacity: 0.3246;
  transform: translate(41.4104vw, -10px) scale(0.1506);
  animation: fall-149 15s -5s linear infinite;
}

@keyframes fall-149 {
  41.618% {
    transform: translate(37.722vw, 41.618vh) scale(0.1506);
  }

  to {
    transform: translate(39.5662vw, 100vh) scale(0.1506);
  }
}

.snow:nth-child(150) {
  opacity: 0.6898;
  transform: translate(50.7446vw, -10px) scale(0.5369);
  animation: fall-150 30s -30s linear infinite;
}

@keyframes fall-150 {
  66.266% {
    transform: translate(52.9101vw, 66.266vh) scale(0.5369);
  }

  to {
    transform: translate(51.82735vw, 100vh) scale(0.5369);
  }
}

.snow:nth-child(151) {
  opacity: 0.0641;
  transform: translate(68.0576vw, -10px) scale(0.4862);
  animation: fall-151 23s -4s linear infinite;
}

@keyframes fall-151 {
  38.666% {
    transform: translate(75.41vw, 38.666vh) scale(0.4862);
  }

  to {
    transform: translate(71.7338vw, 100vh) scale(0.4862);
  }
}

.snow:nth-child(152) {
  opacity: 0.995;
  transform: translate(72.8828vw, -10px) scale(0.4441);
  animation: fall-152 14s -10s linear infinite;
}

@keyframes fall-152 {
  54.128% {
    transform: translate(69.8518vw, 54.128vh) scale(0.4441);
  }

  to {
    transform: translate(71.3673vw, 100vh) scale(0.4441);
  }
}

.snow:nth-child(153) {
  opacity: 0.5563;
  transform: translate(89.6832vw, -10px) scale(0.4796);
  animation: fall-153 16s -10s linear infinite;
}

@keyframes fall-153 {
  58.457% {
    transform: translate(81.0733vw, 58.457vh) scale(0.4796);
  }

  to {
    transform: translate(85.37825vw, 100vh) scale(0.4796);
  }
}

.snow:nth-child(154) {
  opacity: 0.876;
  transform: translate(27.307vw, -10px) scale(0.6013);
  animation: fall-154 24s -27s linear infinite;
}

@keyframes fall-154 {
  70.748% {
    transform: translate(35.1162vw, 70.748vh) scale(0.6013);
  }

  to {
    transform: translate(31.2116vw, 100vh) scale(0.6013);
  }
}

.snow:nth-child(155) {
  opacity: 0.2506;
  transform: translate(87.7301vw, -10px) scale(0.993);
  animation: fall-155 13s -26s linear infinite;
}

@keyframes fall-155 {
  46.076% {
    transform: translate(87.7723vw, 46.076vh) scale(0.993);
  }

  to {
    transform: translate(87.7512vw, 100vh) scale(0.993);
  }
}

.snow:nth-child(156) {
  opacity: 0.7255;
  transform: translate(14.2295vw, -10px) scale(0.5345);
  animation: fall-156 10s -21s linear infinite;
}

@keyframes fall-156 {
  79.832% {
    transform: translate(17.1566vw, 79.832vh) scale(0.5345);
  }

  to {
    transform: translate(15.69305vw, 100vh) scale(0.5345);
  }
}

.snow:nth-child(157) {
  opacity: 0.74;
  transform: translate(25.9907vw, -10px) scale(0.0139);
  animation: fall-157 22s -9s linear infinite;
}

@keyframes fall-157 {
  31.542% {
    transform: translate(20.5603vw, 31.542vh) scale(0.0139);
  }

  to {
    transform: translate(23.2755vw, 100vh) scale(0.0139);
  }
}

.snow:nth-child(158) {
  opacity: 0.9107;
  transform: translate(34.8542vw, -10px) scale(0.7265);
  animation: fall-158 24s -25s linear infinite;
}

@keyframes fall-158 {
  30.533% {
    transform: translate(35.4308vw, 30.533vh) scale(0.7265);
  }

  to {
    transform: translate(35.1425vw, 100vh) scale(0.7265);
  }
}

.snow:nth-child(159) {
  opacity: 0.3505;
  transform: translate(57.8017vw, -10px) scale(0.566);
  animation: fall-159 17s -14s linear infinite;
}

@keyframes fall-159 {
  60.388% {
    transform: translate(57.0244vw, 60.388vh) scale(0.566);
  }

  to {
    transform: translate(57.41305vw, 100vh) scale(0.566);
  }
}

.snow:nth-child(160) {
  opacity: 0.7044;
  transform: translate(88.1477vw, -10px) scale(0.2594);
  animation: fall-160 29s -12s linear infinite;
}

@keyframes fall-160 {
  68.266% {
    transform: translate(87.2763vw, 68.266vh) scale(0.2594);
  }

  to {
    transform: translate(87.712vw, 100vh) scale(0.2594);
  }
}

.snow:nth-child(161) {
  opacity: 0.5051;
  transform: translate(60.2237vw, -10px) scale(0.647);
  animation: fall-161 30s -6s linear infinite;
}

@keyframes fall-161 {
  69.142% {
    transform: translate(65.7239vw, 69.142vh) scale(0.647);
  }

  to {
    transform: translate(62.9738vw, 100vh) scale(0.647);
  }
}

.snow:nth-child(162) {
  opacity: 0.4182;
  transform: translate(96.3045vw, -10px) scale(0.2888);
  animation: fall-162 11s -3s linear infinite;
}

@keyframes fall-162 {
  40.448% {
    transform: translate(100.6887vw, 40.448vh) scale(0.2888);
  }

  to {
    transform: translate(98.4966vw, 100vh) scale(0.2888);
  }
}

.snow:nth-child(163) {
  opacity: 0.0943;
  transform: translate(51.5209vw, -10px) scale(0.6639);
  animation: fall-163 16s -10s linear infinite;
}

@keyframes fall-163 {
  59.316% {
    transform: translate(46.5091vw, 59.316vh) scale(0.6639);
  }

  to {
    transform: translate(49.015vw, 100vh) scale(0.6639);
  }
}

.snow:nth-child(164) {
  opacity: 0.0605;
  transform: translate(82.044vw, -10px) scale(0.1423);
  animation: fall-164 24s -5s linear infinite;
}

@keyframes fall-164 {
  69.027% {
    transform: translate(78.4649vw, 69.027vh) scale(0.1423);
  }

  to {
    transform: translate(80.25445vw, 100vh) scale(0.1423);
  }
}

.snow:nth-child(165) {
  opacity: 0.9726;
  transform: translate(25.7635vw, -10px) scale(0.3959);
  animation: fall-165 11s -5s linear infinite;
}

@keyframes fall-165 {
  58.861% {
    transform: translate(31.2541vw, 58.861vh) scale(0.3959);
  }

  to {
    transform: translate(28.5088vw, 100vh) scale(0.3959);
  }
}

.snow:nth-child(166) {
  opacity: 0.4842;
  transform: translate(6.0454vw, -10px) scale(0.8512);
  animation: fall-166 18s -27s linear infinite;
}

@keyframes fall-166 {
  72.57% {
    transform: translate(11.798vw, 72.57vh) scale(0.8512);
  }

  to {
    transform: translate(8.9217vw, 100vh) scale(0.8512);
  }
}

.snow:nth-child(167) {
  opacity: 0.6841;
  transform: translate(89.3866vw, -10px) scale(0.9309);
  animation: fall-167 13s -16s linear infinite;
}

@keyframes fall-167 {
  54.078% {
    transform: translate(86.2855vw, 54.078vh) scale(0.9309);
  }

  to {
    transform: translate(87.83605vw, 100vh) scale(0.9309);
  }
}

.snow:nth-child(168) {
  opacity: 0.1562;
  transform: translate(25.8666vw, -10px) scale(0.4692);
  animation: fall-168 29s -1s linear infinite;
}

@keyframes fall-168 {
  57.543% {
    transform: translate(16.1639vw, 57.543vh) scale(0.4692);
  }

  to {
    transform: translate(21.01525vw, 100vh) scale(0.4692);
  }
}

.snow:nth-child(169) {
  opacity: 0.8264;
  transform: translate(7.5465vw, -10px) scale(0.0074);
  animation: fall-169 28s -22s linear infinite;
}

@keyframes fall-169 {
  51.288% {
    transform: translate(17.056vw, 51.288vh) scale(0.0074);
  }

  to {
    transform: translate(12.30125vw, 100vh) scale(0.0074);
  }
}

.snow:nth-child(170) {
  opacity: 0.7308;
  transform: translate(99.6679vw, -10px) scale(0.4547);
  animation: fall-170 29s -27s linear infinite;
}

@keyframes fall-170 {
  69.185% {
    transform: translate(98.8494vw, 69.185vh) scale(0.4547);
  }

  to {
    transform: translate(99.25865vw, 100vh) scale(0.4547);
  }
}

.snow:nth-child(171) {
  opacity: 0.0965;
  transform: translate(72.0278vw, -10px) scale(0.5599);
  animation: fall-171 20s -3s linear infinite;
}

@keyframes fall-171 {
  48.375% {
    transform: translate(73.9036vw, 48.375vh) scale(0.5599);
  }

  to {
    transform: translate(72.9657vw, 100vh) scale(0.5599);
  }
}

.snow:nth-child(172) {
  opacity: 0.619;
  transform: translate(8.4793vw, -10px) scale(0.5877);
  animation: fall-172 18s -29s linear infinite;
}

@keyframes fall-172 {
  31.208% {
    transform: translate(6.6617vw, 31.208vh) scale(0.5877);
  }

  to {
    transform: translate(7.5705vw, 100vh) scale(0.5877);
  }
}

.snow:nth-child(173) {
  opacity: 0.0099;
  transform: translate(94.328vw, -10px) scale(0.0547);
  animation: fall-173 30s -2s linear infinite;
}

@keyframes fall-173 {
  50.702% {
    transform: translate(94.9915vw, 50.702vh) scale(0.0547);
  }

  to {
    transform: translate(94.65975vw, 100vh) scale(0.0547);
  }
}

.snow:nth-child(174) {
  opacity: 0.5628;
  transform: translate(53.1306vw, -10px) scale(0.2821);
  animation: fall-174 24s -26s linear infinite;
}

@keyframes fall-174 {
  61.455% {
    transform: translate(50.2055vw, 61.455vh) scale(0.2821);
  }

  to {
    transform: translate(51.66805vw, 100vh) scale(0.2821);
  }
}

.snow:nth-child(175) {
  opacity: 0.5371;
  transform: translate(71.08vw, -10px) scale(0.8559);
  animation: fall-175 10s -21s linear infinite;
}

@keyframes fall-175 {
  54.319% {
    transform: translate(79.8689vw, 54.319vh) scale(0.8559);
  }

  to {
    transform: translate(75.47445vw, 100vh) scale(0.8559);
  }
}

.snow:nth-child(176) {
  opacity: 0.386;
  transform: translate(28.5765vw, -10px) scale(0.708);
  animation: fall-176 15s -29s linear infinite;
}

@keyframes fall-176 {
  54.164% {
    transform: translate(26.2059vw, 54.164vh) scale(0.708);
  }

  to {
    transform: translate(27.3912vw, 100vh) scale(0.708);
  }
}

.snow:nth-child(177) {
  opacity: 0.8694;
  transform: translate(24.1253vw, -10px) scale(0.9798);
  animation: fall-177 26s -8s linear infinite;
}

@keyframes fall-177 {
  41.996% {
    transform: translate(28.2737vw, 41.996vh) scale(0.9798);
  }

  to {
    transform: translate(26.1995vw, 100vh) scale(0.9798);
  }
}

.snow:nth-child(178) {
  opacity: 0.7713;
  transform: translate(78.7217vw, -10px) scale(0.4767);
  animation: fall-178 23s -6s linear infinite;
}

@keyframes fall-178 {
  73.925% {
    transform: translate(69.2782vw, 73.925vh) scale(0.4767);
  }

  to {
    transform: translate(73.99995vw, 100vh) scale(0.4767);
  }
}

.snow:nth-child(179) {
  opacity: 0.1565;
  transform: translate(63.2461vw, -10px) scale(0.3356);
  animation: fall-179 23s -5s linear infinite;
}

@keyframes fall-179 {
  70.73% {
    transform: translate(58.8014vw, 70.73vh) scale(0.3356);
  }

  to {
    transform: translate(61.02375vw, 100vh) scale(0.3356);
  }
}

.snow:nth-child(180) {
  opacity: 0.2117;
  transform: translate(70.1481vw, -10px) scale(0.7078);
  animation: fall-180 25s -14s linear infinite;
}

@keyframes fall-180 {
  34.454% {
    transform: translate(68.6358vw, 34.454vh) scale(0.7078);
  }

  to {
    transform: translate(69.39195vw, 100vh) scale(0.7078);
  }
}

.snow:nth-child(181) {
  opacity: 0.1542;
  transform: translate(16.0442vw, -10px) scale(0.4483);
  animation: fall-181 20s -25s linear infinite;
}

@keyframes fall-181 {
  55.119% {
    transform: translate(12.9661vw, 55.119vh) scale(0.4483);
  }

  to {
    transform: translate(14.50515vw, 100vh) scale(0.4483);
  }
}

.snow:nth-child(182) {
  opacity: 0.7182;
  transform: translate(63.5643vw, -10px) scale(0.2219);
  animation: fall-182 21s -13s linear infinite;
}

@keyframes fall-182 {
  60.804% {
    transform: translate(67.4389vw, 60.804vh) scale(0.2219);
  }

  to {
    transform: translate(65.5016vw, 100vh) scale(0.2219);
  }
}

.snow:nth-child(183) {
  opacity: 0.3083;
  transform: translate(98.9163vw, -10px) scale(0.2629);
  animation: fall-183 19s -5s linear infinite;
}

@keyframes fall-183 {
  78.666% {
    transform: translate(100.9169vw, 78.666vh) scale(0.2629);
  }

  to {
    transform: translate(99.9166vw, 100vh) scale(0.2629);
  }
}

.snow:nth-child(184) {
  opacity: 0.66;
  transform: translate(4.4337vw, -10px) scale(0.9421);
  animation: fall-184 25s -12s linear infinite;
}

@keyframes fall-184 {
  38.204% {
    transform: translate(4.2877vw, 38.204vh) scale(0.9421);
  }

  to {
    transform: translate(4.3607vw, 100vh) scale(0.9421);
  }
}

.snow:nth-child(185) {
  opacity: 0.0147;
  transform: translate(80.3726vw, -10px) scale(0.7988);
  animation: fall-185 30s -18s linear infinite;
}

@keyframes fall-185 {
  62.478% {
    transform: translate(85.1392vw, 62.478vh) scale(0.7988);
  }

  to {
    transform: translate(82.7559vw, 100vh) scale(0.7988);
  }
}

.snow:nth-child(186) {
  opacity: 0.0415;
  transform: translate(60.2043vw, -10px) scale(0.784);
  animation: fall-186 22s -19s linear infinite;
}

@keyframes fall-186 {
  61.187% {
    transform: translate(58.713vw, 61.187vh) scale(0.784);
  }

  to {
    transform: translate(59.45865vw, 100vh) scale(0.784);
  }
}

.snow:nth-child(187) {
  opacity: 0.2607;
  transform: translate(36.8732vw, -10px) scale(0.4344);
  animation: fall-187 21s -12s linear infinite;
}

@keyframes fall-187 {
  32.987% {
    transform: translate(41.2759vw, 32.987vh) scale(0.4344);
  }

  to {
    transform: translate(39.07455vw, 100vh) scale(0.4344);
  }
}

.snow:nth-child(188) {
  opacity: 0.6858;
  transform: translate(0.3539vw, -10px) scale(0.5626);
  animation: fall-188 12s -1s linear infinite;
}

@keyframes fall-188 {
  69.184% {
    transform: translate(6.8637vw, 69.184vh) scale(0.5626);
  }

  to {
    transform: translate(3.6088vw, 100vh) scale(0.5626);
  }
}

.snow:nth-child(189) {
  opacity: 0.9808;
  transform: translate(20.4689vw, -10px) scale(0.0068);
  animation: fall-189 27s -21s linear infinite;
}

@keyframes fall-189 {
  34.089% {
    transform: translate(21.461vw, 34.089vh) scale(0.0068);
  }

  to {
    transform: translate(20.96495vw, 100vh) scale(0.0068);
  }
}

.snow:nth-child(190) {
  opacity: 0.1257;
  transform: translate(12.787vw, -10px) scale(0.6866);
  animation: fall-190 21s -18s linear infinite;
}

@keyframes fall-190 {
  76.474% {
    transform: translate(7.7522vw, 76.474vh) scale(0.6866);
  }

  to {
    transform: translate(10.2696vw, 100vh) scale(0.6866);
  }
}

.snow:nth-child(191) {
  opacity: 0.9406;
  transform: translate(35.409vw, -10px) scale(0.0801);
  animation: fall-191 14s -7s linear infinite;
}

@keyframes fall-191 {
  32.671% {
    transform: translate(40.5051vw, 32.671vh) scale(0.0801);
  }

  to {
    transform: translate(37.95705vw, 100vh) scale(0.0801);
  }
}

.snow:nth-child(192) {
  opacity: 0.3938;
  transform: translate(74.8114vw, -10px) scale(0.456);
  animation: fall-192 11s -8s linear infinite;
}

@keyframes fall-192 {
  45.382% {
    transform: translate(69.3947vw, 45.382vh) scale(0.456);
  }

  to {
    transform: translate(72.10305vw, 100vh) scale(0.456);
  }
}

.snow:nth-child(193) {
  opacity: 0.4625;
  transform: translate(45.0449vw, -10px) scale(0.9842);
  animation: fall-193 11s -6s linear infinite;
}

@keyframes fall-193 {
  64.355% {
    transform: translate(42.1847vw, 64.355vh) scale(0.9842);
  }

  to {
    transform: translate(43.6148vw, 100vh) scale(0.9842);
  }
}

.snow:nth-child(194) {
  opacity: 0.9101;
  transform: translate(4.0332vw, -10px) scale(0.3342);
  animation: fall-194 15s -12s linear infinite;
}

@keyframes fall-194 {
  75.219% {
    transform: translate(0.2323vw, 75.219vh) scale(0.3342);
  }

  to {
    transform: translate(2.13275vw, 100vh) scale(0.3342);
  }
}

.snow:nth-child(195) {
  opacity: 0.7367;
  transform: translate(64.1241vw, -10px) scale(0.355);
  animation: fall-195 30s -16s linear infinite;
}

@keyframes fall-195 {
  44.003% {
    transform: translate(65.4366vw, 44.003vh) scale(0.355);
  }

  to {
    transform: translate(64.78035vw, 100vh) scale(0.355);
  }
}

.snow:nth-child(196) {
  opacity: 0.7519;
  transform: translate(72.684vw, -10px) scale(0.6416);
  animation: fall-196 27s -30s linear infinite;
}

@keyframes fall-196 {
  39.974% {
    transform: translate(71.6151vw, 39.974vh) scale(0.6416);
  }

  to {
    transform: translate(72.14955vw, 100vh) scale(0.6416);
  }
}

.snow:nth-child(197) {
  opacity: 0.7151;
  transform: translate(78.6785vw, -10px) scale(0.8337);
  animation: fall-197 23s -9s linear infinite;
}

@keyframes fall-197 {
  40.127% {
    transform: translate(83.1223vw, 40.127vh) scale(0.8337);
  }

  to {
    transform: translate(80.9004vw, 100vh) scale(0.8337);
  }
}

.snow:nth-child(198) {
  opacity: 0.3447;
  transform: translate(44.5475vw, -10px) scale(0.7869);
  animation: fall-198 10s -25s linear infinite;
}

@keyframes fall-198 {
  42.68% {
    transform: translate(45.4763vw, 42.68vh) scale(0.7869);
  }

  to {
    transform: translate(45.0119vw, 100vh) scale(0.7869);
  }
}

.snow:nth-child(199) {
  opacity: 0.5576;
  transform: translate(38.8871vw, -10px) scale(0.5114);
  animation: fall-199 26s -3s linear infinite;
}

@keyframes fall-199 {
  40.36% {
    transform: translate(47.5519vw, 40.36vh) scale(0.5114);
  }

  to {
    transform: translate(43.2195vw, 100vh) scale(0.5114);
  }
}

.snow:nth-child(200) {
  opacity: 0.0896;
  transform: translate(41.7779vw, -10px) scale(0.4455);
  animation: fall-200 11s -15s linear infinite;
}

@keyframes fall-200 {
  71.348% {
    transform: translate(31.8059vw, 71.348vh) scale(0.4455);
  }

  to {
    transform: translate(36.7919vw, 100vh) scale(0.4455);
  }
}
